import { request, gql, GraphQLClient } from "graphql-request";
import { ISubject, ISubjects } from "src/hooks/useSubjects";
import { IPost, IPosts } from "src/interfaces/post";

const graphqlAPI: string = process.env.REACT_APP_GRAPHQL_API_URI || "";
const token: string = process.env.REACT_APP_GRAPHQL_TOKEN || "";
const client = new GraphQLClient(graphqlAPI, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const fetchPosts = async (limit: number, skip: number) => {
  const query = gql`
    query fetchPosts($limit: Int!, $skip: Int!) {
      postsConnection(first: $limit, skip: $skip) {
        edges {
          node {
            slug
            title
            updatedAt
            id
            excerpt
            createdAt
            featuredImage {
              url
            }
            content {
              html
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        aggregate {
          count
        }
      }
    }
  `;

  const result = await request(graphqlAPI, query, { limit, skip }).then(
    (res) => {
      const edges: IPost[] = res.postsConnection.edges.map(
        (edge: any) => edge.node
      );
      const pageInfo = res.postsConnection.pageInfo;
      pageInfo.total = res.postsConnection.aggregate.count;
      const result: IPosts = {
        items: edges,
        pageInfo,
      };
      return result;
    }
  );

  return result;
};

export const fetchPost = (slug: string) => {
  const query = gql`
    query fetchPost($slug: String!) {
      post(where: { slug: $slug }) {
        id
        slug
        title
        excerpt
        content {
          html
        }
        featuredImage {
          url
        }
        createdAt
        updatedAt
      }
    }
  `;

  const result = request(graphqlAPI, query, { slug }).then((result) => {
    return result.post;
  });
  return result;
};

export const fetchSubjects = async (limit: number, skip: number) => {
  const query = gql`
    query fetchSubject($limit: Int!, $skip: Int!) {
      canWeTalksConnection(first: $limit, skip: $skip) {
        edges {
          node {
            subject
            authorEmail
            authorName
            content {
              text
            }
            publishedAt
            id
            primaryImage {
              url
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        aggregate {
          count
        }
      }
    }
  `;

  const result = await request(graphqlAPI, query, { limit, skip }).then(
    (res) => {
      const edges: ISubject[] = res.canWeTalksConnection.edges.map(
        (edge: any) => edge.node
      );
      const pageInfo = res.canWeTalksConnection.pageInfo;
      pageInfo.total = res.canWeTalksConnection.aggregate.count;
      const result: ISubjects = {
        items: edges,
        pageInfo,
      };
      return result;
    }
  );

  return result;
};

export const fetchSubject = async (id: string) => {
  const query = gql`
    query fetchSubject($id: ID!) {
      canWeTalk(where: { id: $id }) {
        content {
          html
        }
        id
        primaryImage {
          url
        }
        publishedAt
        subject
        authorName
        authorEmail
      }
    }
  `;

  const result = request(graphqlAPI, query, { id }).then((result) => {
    return result.canWeTalk;
  });
  return result;
};
export const submitSubject = async (obj: any) => {
  console.log(obj);

  const query = gql`
    mutation CreateCanWeTalk(
      $name: String!
      $email: String!
      $subject: String!
      $uploadedImage: ID!
      $parsedContent: RichTextAST!
    ) {
      createCanWeTalk(
        data: {
          authorName: $name
          authorEmail: $email
          subject: $subject
          primaryImage: { connect: { id: $uploadedImage } }
          content: $parsedContent
        }
      ) {
        id
      }
    }
  `;

  const result = await client.request(query, obj);
  return result;
};

export const submitComment = async (obj: any) => {
  const query = gql`
    mutation CreateComment(
      $name: String!
      $email: String!
      $comment: String!
      $chatId: String!
    ) {
      createComment(
        data: {
          authorName: $name
          authorEmail: $email
          message: $comment
          chatId: $chatId
        }
      ) {
        id
      }
    }
  `;

  const result = await client.request(query, obj);
  return result;
};

export const fetchComments = async (chatId: string) => {
  const query = gql`
    query fetchComments($chatId: String!) {
      comments(where: { chatId: $chatId }, orderBy: createdAt_DESC) {
        id
        authorName
        authorEmail
        message
      }
    }
  `;
  const result = await client
    .request(query, { chatId })
    .then((data) => data.comments);
  return result;
};

export const publishComment = async (id: string) => {
  const publishRequest = gql`
    mutation PublishComment($id: ID!) {
      publishComment(where: { id: $id }) {
        id
      }
    }
  `;

  const result = await client.request(publishRequest, { id });
  return result;
};
