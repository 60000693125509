import {useCallback, useEffect, useState} from "react";
import {Modal} from "@mantine/core";
import styled from "styled-components";
import {down, up} from "../../styles/breakpoints";
import {useLocalStorage} from "../../hooks/useLocalStorage";

const Root = styled.div`
  display: flex;
  flex-direction: column;

  .mantine-Modal-title {
    font-size: 30px;
    //font-family: 'Roboto';
  }
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: #361534;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 248px;
  max-width: 248px;
  max-height: 48px;
  min-height: 48px;
  flex: 1;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }

  ${up("xlg")} {
    margin-top: 45px;
    max-width: 326px;
    max-height: 74px;
    min-height: 74px;
    font-size: 18px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 20px;
    line-height: 1.5;
    font-family: 'Roboto';
  }

  ${down('sm')} {
    p {
      font-size: 16px;
    }
  }

`

const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

const ModalStyles = {
    title: {
        fontSize: down('sm') ? '24px' : '30px',
        fontFamily: 'Roboto'
    }
}


export const Disclaimer = () => {
    const [opened, setOpened] = useState(false)
    const  {isDisclaimer, AcceptDisclaimer} = useLocalStorage()
    useEffect(() => {
        const body = document.querySelector('body')
        if (!body) return
        if (opened)
            body.style.overflow = 'hidden'
        else body.style.overflow = 'auto'
    }, [opened])

    const handleClose = useCallback(() =>  {
        setOpened(false)
        AcceptDisclaimer()
    }, [AcceptDisclaimer, setOpened])

    useEffect(() => {
        if(!isDisclaimer) setOpened(true)
        else setOpened(false)
    }, [isDisclaimer])


    return (
        <Root>
            <Modal styles={ModalStyles} centered={true} size={'xl'} opened={opened}
                   onClose={handleClose} title={'Disclaimer'}>
                <Content>
                    <p>This is a personal blog/website and the opinions, beliefs and view points expressed by the
                        various
                        authors and forum participants on this website are strictly their own. They do not represent
                        those
                        of people, institutions or organizations that the owner may or may not be associated with in a
                        professionsl or personal capacity, unless explicity stated. Any views or opinions are not
                        intended
                        to malign any religion, ethnic group, club, orgazination, company or individual. The resources
                        on
                        this site are provided for informational purposes only, and should not be used to replace the
                        specialized training and professional judgment of a health care or mental health care
                        professional
                    </p>
                    <Buttons>
                        <StyledButton onClick={handleClose}>Accept</StyledButton>
                    </Buttons>
                </Content>
            </Modal>
        </Root>
    )
}