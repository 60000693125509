import { useQuery } from "react-query";
import { IPosts } from "src/interfaces/post";
import { fetchPosts, fetchSubjects } from "src/services";

export interface ISubjects {
    items: ISubject[];

    pageInfo: {
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        total: number;
    };
}

export interface ISubject {
    subject: string;
    authorEmail: string;
    authorName: string;
    content: {
        html: string;
    }
    publishedAt: string;
    id: string;
    primaryImage: {
        url: string;
    }
}

export const useSubjects = (limit: number, skip: number) => {
  const { data, refetch, isFetched } = useQuery<ISubjects>(
    "CAN_WE_TALK",
    () => fetchSubjects(limit, skip),
    { retry: 0 }
  );

  return { data, refetch, isFetched };
};
