import styled from "styled-components";
import { useCallback, useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
// import Loader from "src/images/ajax-loader.gif";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSSTransition } from "react-transition-group";
import Loader from "src/components/Loader";
import { up } from "src/styles/breakpoints";

const StyledForm = styled.form`
  width: 100%;
  margin-bottom: 40px;

  .row {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block: 10px;
  input,
  textarea {
    font-family: "Montserrat";
    font-weight: 500;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    max-width: 790px;
    border: 1px solid #d2d2d2;
    background: rgba(0, 0, 0, 0.1);
    margin-block: 4px;
    font-size: 13px;
    color: #747474;
    padding: 26px;
    float: left;
    margin-right: 1%;
    outline: none;
  }
  textarea {
    height: 220px;
    resize: none;
  }

  ${up("xlg")} {
    margin-block: 15px;
    input,
    textarea {
      font-family: "Montserrat";
      font-weight: 500;
      height: 72px;
      font-size: 18px;
      line-height: 22px;
    }
    textarea {
      height: 280px;
    }
  }
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #361534;
  color: #fff;
  border: none;
  cursor: pointer;
  max-width: 248px;
  height: 48px;
  flex: 1;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }

  ${up("xlg")} {
    margin-top: 45px;
    max-width: 326px;
    height: 74px;
    font-size: 18px;
  }
`;

export const ContacntUs = () => {
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    init("user_iAXGObJwMu2Q3BUFbDZZQ");
  }, [init]);

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      setSubmitting(true);
      e.preventDefault();

      const target = e.target as typeof e.target & {
        email: { value: string };
        name: { value: string };
        subject: { value: string };
        message: { value: string };
      };
      const email = target.email.value;
      const name = target.name.value;
      const subject = target.subject.value;
      const message = target.message.value;
      if (!email || !name) {
        toast.error(
          "Validation errors occurred. Please confirm the fields and submit it again."
        );
        setSubmitting(false);
        return;
      }

      emailjs
        .send("service_e146fyp", "template_wqo13sy", {
          message: message,
          email: email,
          subject: subject,
          name: name,
        })
        .then(() => {
          setSubmitting(false);

          toast.success("Your message was sent successfully. Thanks.");
        })
        .catch((error) => setSubmitting(false));
    },
    [toast]
  );

  return (
    <>
      <h1>Contact Us</h1>
      <h2>Please use this form to contact us.</h2>
      <p>
        <span>
          Your privacy is important to us and we will never share anything you
          submit.
        </span>
      </p>

      <StyledForm onSubmit={handleSubmit}>
        <FormField>
          <input placeholder="Your Name *" type="text" name="name" />
        </FormField>
        <FormField>
          <input placeholder="Your Email  *" type="email" name="email" />
        </FormField>
        <FormField>
          <input placeholder="Subject" type="text" name="subject" />
        </FormField>
        <FormField>
          <textarea placeholder="Your Message" name="message"></textarea>
        </FormField>
        <div className="row">
          <SubmitButton disabled={submitting} type="submit">
            Send message
          </SubmitButton>
        </div>
      </StyledForm>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <CSSTransition in={submitting} timeout={200} unmountOnExit>
        <Loader
          text={"<span>Please Wait. <br/> Your message is submitting</span>"}
        />
      </CSSTransition>
    </>
  );
};
