import React from "react";
import styled from "styled-components";
import Mediflix1 from 'src/images/Mediflix1.png'
import Mediflix2 from 'src/images/Mediflix2.png'
// import { down, up } from "../../styles/breakpoints";
import { useCarousel } from "./useCarousel";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-block: 20px;

`

const CarouselWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const CarauselViewport = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  &.is-draggable {
    cursor: move;
    cursor: grab;
  }
  
  &.is-dragging {
    cursor: grabbing;
  }
`

const CarouselContainer = styled.div`
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`

const Slide = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  
`

const SlideInner = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`

const SlideImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Navigation = styled.div`
  display: flex;
  gap: 40px;
  margin-block: 20px;
  align-self: center;

`

const NavButton = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  width: 16px;
  height: 16px;
  background: rgba(54, 21, 52, 0.5);
  border-radius: 50%;
  cursor: pointer;
  &.selected {
    background-color: #361534;
    &::after {
      position: absolute;
      top: -8px;
      left: -8px;
      content: '';
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid rgba(54, 21, 52, 0.5);
    }
  }
`


const slides = [
  {
    img: Mediflix1,
    link: "https://www.mediflix.com/video/61add4bcc1a5af18775d56e6"
  },
  {
    img: Mediflix2,
    link: "https://www.mediflix.com/video/61add4f77a5bc718832f326e"
  }
]

export const HomeCarousel = () => {
  const { emblaRef, currentSlide, scrollSnaps, scrollTo, scrollNext, scrollPrev } = useCarousel({
    delay: 50000,
    autoplay: true,
    slides
  })


  return (
    <Wrapper>
      <CarouselWrapper>
        <CarauselViewport ref={emblaRef}>
          <CarouselContainer>
            {slides.map((el, index) => (
              <Slide key={`${index}`}>
                <a href={el.link} target="_blank">
                  <SlideInner>
                    <SlideImg
                      src={el.img}
                    />
                  </SlideInner>
                </a>
              </Slide>
            ))}
          </CarouselContainer>
        </CarauselViewport>
      </CarouselWrapper>

      <Navigation>
        {scrollSnaps.map((_, index) => (
          <NavButton onClick={() => scrollTo(index)} className={currentSlide === index ? "selected" : ''} key={`${index}`} />
        ))}
      </Navigation>
    </Wrapper >
  )
}

