export const ArowDownIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="filled"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M277 4009 c-103 -24 -197 -103 -244 -204 -23 -51 -28 -73 -27 -145 0
          -160 -96 -52 1192 -1342 777 -778 1160 -1155 1191 -1172 73 -39 158 -53 234
          -37 34 7 83 24 108 37 31 17 414 394 1191 1172 1288 1290 1192 1182 1192 1342
          0 72 -4 94 -28 147 -84 184 -308 262 -491 171 -26 -13 -388 -368 -1037 -1016
          l-998 -997 -998 997 c-652 651 -1011 1003 -1037 1016 -76 37 -170 49 -248 31z"
        />
      </g>
    </svg>
  );
};

export const ArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="filled"
      viewBox="0 0 32 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path
          d="M27 18L15.75 6.75L4.5 18L0 15.75L15.75 0L31.5 15.75L27 18Z"
          fill="black"
          fillOpacity="0.5"
        />
    </svg>
  );
};

export const FaceBookIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="filled"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 12.8008C25 5.85353 19.4052 0.224731 12.5 0.224731C5.59476 0.224731 0 5.85353 0 12.8008C0 19.0787 4.57107 24.2815 10.5494 25.2247V16.4367H7.37399V12.8008H10.5494V10.03C10.5494 6.87838 12.4143 5.13751 15.2707 5.13751C16.6386 5.13751 18.0691 5.38295 18.0691 5.38295V8.47625H16.4924C14.94 8.47625 14.4556 9.44583 14.4556 10.4402V12.8008H17.9224L17.3679 16.4362H14.4556V25.2247C20.4284 24.2815 25 19.0787 25 12.8008Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const TwitterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className="filled"
      viewBox="0 0 31 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.812 6.41082C27.8317 6.68485 27.8317 6.95894 27.8317 7.23327C27.8317 15.5903 21.4384 25.2247 9.75351 25.2247C6.15422 25.2247 2.8092 24.1872 0 22.3862C0.511409 22.4449 1.0032 22.4645 1.53416 22.4645C4.50441 22.4645 7.23794 21.4661 9.42295 19.7634C6.63009 19.7046 4.28949 17.8841 3.48305 15.3788C3.87646 15.4375 4.26951 15.4766 4.68302 15.4766C5.2534 15.4766 5.82365 15.3983 6.35462 15.2613C3.4437 14.6741 1.26051 12.1294 1.26051 9.10954V9.03127C2.1063 9.50106 3.08952 9.79467 4.13207 9.83384C2.42112 8.69867 1.29986 6.76034 1.29986 4.56835C1.29986 3.39402 1.61451 2.31731 2.16503 1.37797C5.29208 5.21426 9.99326 7.72258 15.2666 7.99372C15.1683 7.52393 15.1092 7.03449 15.1092 6.54524C15.1092 3.06083 17.9414 0.224731 21.4602 0.224731C23.2892 0.224731 24.9414 0.988133 26.1021 2.22151C27.5381 1.94748 28.9149 1.41894 30.1342 0.694703C29.6621 2.16306 28.6588 3.39583 27.3414 4.17912C28.62 4.04216 29.8588 3.68975 31 3.20061C30.1348 4.45327 29.0529 5.56915 27.8136 6.46932L27.812 6.41082Z"
        fill="currentColor"
      />
    </svg>
  );
};
