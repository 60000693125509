import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useComments } from "src/hooks/usePost";
import { IPost } from "src/interfaces/post";
import { publishComment, submitComment } from "src/services";
import styled from "styled-components";
import { Comment } from "./Comment";

type componentProps = {
  id: string;
};

interface IErrors {
  name: string;
  email: string;
  message: string;
}

const validateEmail = (email:string) => {
  return email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const PostComments: React.FC<componentProps> = ({ id }) => {
  const { data: comments, refetch } = useComments(id);
  const [errors, setErrors] = useState<IErrors>();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let validation: IErrors = {
        name: '',
        email: '',
        message: ''
      };
      const target = e.target as typeof e.target & {
        email: { value: string };
        name: { value: string };
        message: { value: string };
      };
      const email = target.email.value;
      const name = target.name.value;
      const comment = target.comment.value;

      const payload = { email, name, comment, chatId: id };

      if(!validateEmail(email)) validation.email = "Invalid email";
      if (!email) validation.email = "Required";
      if (!name) validation.name = "Required";
      if (!comment) validation.message = "Required";

      setErrors(validation);

      if (validation.name || validation.email || validation.message) {

        return;
      }
      submitComment(payload)
        .then((res) => {
          publishComment(res.createComment.id).then((res) => {
            refetch();
            toast.success(`Comment successfully published`)
          });
        })
        .catch((err) => {

          toast.error(err)
        });
    },
    [submitComment, publishComment, refetch, setErrors, errors]
  );
  return (
    <Root>
      <h3>Post your comment</h3>
      <StyledForm onSubmit={handleSubmit}>
        <FormField>
          <Input className={errors?.name ? "errors" : ""}>
            <input type="text" name="name" placeholder="Your name *" />
          </Input>
          {errors?.name && <Error>{errors.name}</Error>}
        </FormField>

        <FormField>
          <Input className={errors?.email ? "errors" : ""}>
            <input type="text" name="email" placeholder="Your address *" />
          </Input>
          {errors?.email && <Error>{errors.email}</Error>}
        </FormField>

        <FormField>
          <Input className={errors?.message ? "errors" : ""}>
            <textarea placeholder="Your Message" name="comment"></textarea>
          </Input>
          {errors?.message && <Error>{errors.message}</Error>}
        </FormField>

        <SubmitButton type="submit">Send comment</SubmitButton>
      </StyledForm>

      <CommentList>
        <h3>Comments ({comments?.length})</h3>
        {comments?.map((comment) => (
          <Comment key={comment.id} comment={comment} />
        ))}
      </CommentList>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  /* max-width: 600px; */
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 8px;
`;

const Input = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);

  input,
  textarea {
    display: flex;
    width: 100%;
    font-family: "Roboto";
    padding: 16px 26px;
    background: none;
    border: none;
    outline: none;
    font-size: 14px;
  }

  textarea {
    height: 160px;
    resize: none;
  }

  &.errors {
    border: 1px solid #d42a4f;
  }
`;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #361534;
  color: #fff;
  border: none;
  cursor: pointer;
  max-width: 248px;
  height: 48px;
  flex: 1 auto;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  margin-top: 16px;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
`;

const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Error = styled.span`
  color: #d42a4f;
  font-size: 12px;
  margin-left: 8px;
  margin-top: 8px;
`;
