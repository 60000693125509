import React from "react";
import { BrowserRouter } from "react-router-dom";
import RootRouter from "./features/RootRouter";
import { ScrollTopButton } from "./components/ScrollTopButton";
import Layout from "./features/Layout";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Layout>
          <RootRouter />
          <ScrollTopButton />
        </Layout>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
