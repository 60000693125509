

export const uploadFile = (file: File) => {
    const responses: any = {}

    return new Promise<void>((resolve, reject) => {
        if (!file) reject(undefined)
        const formData = new FormData();

        formData.append('fileUpload', file)

        fetch(`${process.env.REACT_APP_GRAPHQL_API_URI}/upload`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_GRAPHQL_TOKEN}`,
            },
            body: formData,
        }).then((e) => {
            e.json().then((response) => {
                console.log(response)
                responses.id = response.id
                return resolve(responses.id)

            })
        })
    })
    // if (!file) return


    // return {
    //     handleUploadFile,
    //     fileId
    // }

}