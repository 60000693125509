import DanaBio from "src/images/DanaBio.jpg";
import styled from "styled-components";
import { up } from "src/styles/breakpoints";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${up('sm')} {
    display: block;
  }
`

const Atachment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  float: right;
  padding: 36px;

  ${up('sm')} {
    padding: 0 0 36px 36px;
  }
  img {
    width: 100%;
    box-shadow: -18px 18px 0px 5px rgba(38, 17, 53, 0.1);
  }
`;


export const Bio = () => {
  return (
    <Root>
      <h1>Bio</h1>
      <Atachment>
        <img src={DanaBio} alt="" />
      </Atachment>

      <p>
        A graduate of the University of Akron, and a certified bariatric coach,
        Dana M. Rosser is dedicated to educating people on how to support a
        loved-ones challenge with obesity. Dana’s unique vantage point offers
        comfort and understanding from the of often-overlooked spousal/family
        members perspective. Her focus is to lead the mass of supporters out of
        seclusion, address their emotions, and help them navigate through the
        delicate issues of living, loving and caring for a loved one who suffers
        with the disease of obesity. Through her efforts, she hopes to aid in
        strengthening communication of the family unit as they learn to conquer
        this obstacle as a unified front.
      </p>
      <p>
        Dana’s article on this delicate subject have appeared in the Southern
        Writers Magazine, Bariatrics Today, Your Weight Matters and <a href="https://www.today.com/" target={"_blank"}>Today.com</a>, to name a
        few. She has given a TEDx talk and appeared on Good Day Orlando, the Dr.
        Oz show, and Monsters in the Morning (I-heart radio) sharing her
        experiences. Dana has lectured for the National Medical Association
        (Regional & National), Harvard Patients Safety and Obesity surgery
        conference, the American Academy of Family Practitioners, and the
        Cleveland Clinic.
      </p>
      <p>
        Dana resides in Orlando Florida and enjoys spending time with her family
        and friends. Her favorite past times are hiking, painting, and
        traveling.
      </p>
    </Root>
  );
};
