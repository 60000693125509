import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { navItems } from "./navItems"
import { CSSTransition } from 'react-transition-group';
import { ArowDownIcon } from "src/components/svg";
import { up } from "src/styles/breakpoints";

const Root = styled.div`
  width: 100%;
`

const MenuWrapper = styled.div`
  margin: 10px 20px;


  ${up('sm')} {
    margin: 10px 40px;
  }
`

const MenuSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 33px;
  border: 1px solid #e5e5e5;
  background-color: #f9f9f9;
  cursor: pointer;
  span {
    font-size: 12px;
    padding-left: 10px;
  }
`

const BurgerButton = styled.button`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: center;
  border: none;
  width: 44px;
  height: 100%;
  border-left: 1px solid #e5e5e5;
  cursor: pointer;
  span {
    width: 24px;
    height: 4px;
    background-color: #dadada;
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }

`

const NavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  background-color: #f9f9f9;
  padding: 0;
  margin: 0;
  border-inline: 1px solid #e5e5e5;
  li {
    width: 100%;
    border-bottom: 1px solid #DBDBDB;
    cursor: pointer;
    font-family: 'Roboto';
    a, span {
      color: #333333;
      padding: 10px 12px;
      font-size: 12px;
      display: block;
      line-height: normal;
    }

    svg {
      fill: #333333;
      margin-right: 15px;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }



  min-height: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
`

const SubMenu = styled.div`
  li {
    a {
        padding-left: 26px;
    }
  }

  svg {
    transition: 0.2s;
  }

  &.active {
    svg {
      transform: rotate(180deg);
      transition: 0.2s;
    }
  }
`

export const MobileNavigation = () => {
  const [active, setActive] = useState(false)
  const [menuHeight, setMenuHeight] = useState<number>(0)
  const [subStatus, setSubStatus] = useState(false)
  const [subHeight, setSubHeight] = useState(0)
  const [activeId, setActiveId] = useState<number | null>(null)

  const calcHeight = (el: any, type: string) => {
    const height = el.offsetHeight
    if (type === 'main') setMenuHeight(height)
    else setSubHeight(height)
  }

  const handleOpenSub = (index: number) => {
    setSubStatus(!subStatus)
    if(subStatus) {
      setActiveId(index)
    } else {
      setActiveId(null)
    }

  }


  useEffect(() => {
    if (!active) {
      setMenuHeight(0)
    }
    if (!subStatus) {
      setSubHeight(0)
    }
  }, [active, subStatus, setMenuHeight, setSubHeight])

  return (
    <Root>
      <MenuWrapper>
        <MenuSelector onClick={() => setActive(!active)}>
          <span>Go to...</span>
          <BurgerButton>
            <span></span>
            <span></span>
            <span></span>
          </BurgerButton>
        </MenuSelector>
        <NavMenu style={{ maxHeight: `${menuHeight + subHeight}px` }}>
          <CSSTransition
            in={active}
            timeout={200}
            unmountOnExit
            onEnter={(el: any) => calcHeight(el, 'main')}
          >
            <div>
              {navItems.map((navItem, index) => (
                !navItem?.items ?
                  <li key={`${navItem.name}-${index}`}>
                    <Link to={navItem.link}>{navItem.name}</Link>
                  </li>
                  :
                  <SubMenu key={`${index}-${navItem.name}`} className={activeId === index ? "active" : ''}>
                    <li onClick={() => handleOpenSub(index)}>
                      <div className="row">
                        <span>{navItem.name}</span>
                        <ArowDownIcon height="10" />
                      </div>
                    </li>
                    <NavMenu style={{ maxHeight: `${subHeight}px`, border: 'none'}}>
                      <CSSTransition
                        in={subStatus}
                        timeout={200}
                        unmountOnExit
                        onEnter={(el: any) => calcHeight(el, 'sub')}
                      >
                        <div>
                          {navItem.items.map((subItem) => (
                            <li key={`${subItem.name}`}>
                              <Link to={subItem.link}>{subItem.name}</Link>
                            </li>
                          ))}

                        </div>
                      </CSSTransition>
                    </NavMenu>
                  </SubMenu>

              ))}
            </div>
          </CSSTransition>
        </NavMenu>
      </MenuWrapper>
    </Root>
  )
}