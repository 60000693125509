import { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Pagination from "src/components/paginations";
import { usePosts } from "src/hooks/usePosts";
import { IPost } from "src/interfaces/post";
import { up } from "src/styles/breakpoints";
import styled from "styled-components";

export const Blog = () => {
  const [currentPage, setCurrentPage] = useState<number | string>(1);

  const limit = 9;

  const {
    data: posts,
    refetch,
    isFetched,
  } = usePosts(limit, (Number(currentPage) - 1) * limit);

  const changePage = useCallback(
    (page) => {
      setCurrentPage(page);
    },
    [setCurrentPage, currentPage]
  );

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  return (
    <Root>
      <Header>
        <h1>Blog</h1>
      </Header>

      {isFetched && !!posts?.items.length && (
        <>
          <PostList>
            {posts.items?.map((post: IPost, index: number) => (
              <Link to={`blog/${post.slug}`} key={`${post.title}-${index}`}>
                <Post>
                  <PostImage>
                    <img src={post?.featuredImage?.url} alt="" />
                  </PostImage>
                  <PostDetails>
                    <h4>{post.title}</h4>
                    <p>{post.excerpt}</p>
                  </PostDetails>
                </Post>
              </Link>
            ))}
          </PostList>
          <Pagination
            currentPage={Number(currentPage)}
            onPageChange={(page) => changePage(page)}
            pageSize={limit}
            totalCount={posts.pageInfo.total}
            siblingCount={1}
            pageInfo={posts.pageInfo}
          />
        </>
      )}
      <>
        {isFetched && !posts?.items?.length && (
          <h3 style={{ color: `rgba(0, 0, 0, 0.5)` }}>Blog list is empty</h3>
        )}
      </>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: inherit;
    height: fit-content;
  }
`;
const Header = styled.div`
  margin-bottom: 20px;
`;

const PostList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  ${up("sm")} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${up("md")} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Post = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 300px; */
  flex: 1;
`;

const PostImage = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  /* max-width: 300px;
  max-height: 300px; */
  border-radius: 4px 4px 0 0;
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    transition: 0.2s;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }
`;

const PostDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 108px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  color: #000;
  background: #fff;
  border-radius: 0 0 4px 4px;
  p {
    display: -webkit-box;
    color: rgba(0, 0, 0, 0.5);

    /* max-width: 60px; */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
    margin: 0;
  }
`;
