import Img1 from 'src/images/press/1.jpg'
import Img2 from 'src/images/press/2.jpg'
import Img3 from 'src/images/press/3.jpg'
import Img4 from 'src/images/press/4.jpg'
import Img5 from 'src/images/press/5.jpg'
import Img6 from 'src/images/press/6.jpg'

import Img7 from 'src/images/press/7.jpg'
import Img8 from 'src/images/press/8.jpg'
import Img9 from 'src/images/press/9.jpg'
import Img10 from 'src/images/press/10.jpg'
import Img11 from 'src/images/press/11.jpg'
import Img12 from 'src/images/press/12.jpg'
import Img13 from 'src/images/press/13.jpg'
import Img14 from 'src/images/press/14.jpg'
import Img15 from 'src/images/press/15.jpg'
import Img16 from 'src/images/press/16.png'


export const PressImages1:string[] = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    Img12,
    Img13,
    Img14,
    Img15,
    Img16
]
