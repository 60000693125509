import { DOTS, usePagination } from "src/hooks/usePagination";
import styled from "styled-components";
import { ArrowUpIcon } from "./svg";

type componentProps = {
  onPageChange: (page: number | string) => void;
  totalCount: number;
  pageSize: number;
  currentPage: number;
  siblingCount?: number;
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    total: number;
  }
};

const Pagination = ({
  onPageChange,
  totalCount,
  pageSize,
  currentPage,
  siblingCount = 1,
  pageInfo
}: componentProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (!paginationRange) return null;

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <PaginationContainer>
      <li
        className={`pagination-item ${!pageInfo.hasPreviousPage ? "disabled" : ""}`}
        onClick={onPrevious}
      >
        <ArrowUpIcon className="arrow left" height="8" />
      </li>
      {paginationRange?.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={`${pageNumber}-${index}`} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={`${pageNumber}`}
            className={`pagination-item ${
              pageNumber === currentPage ? "selected" : ""
            }`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`pagination-item ${
          !pageInfo.hasNextPage ? "disabled" : ""
        }`}
        onClick={onNext}
      >
        <ArrowUpIcon className="arrow right" height="8" />
      </li>
    </PaginationContainer>
  );
};

export default Pagination;

// styles

const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  justify-content: center;
  font-family: "Roboto";
  user-select: none;
  .pagination-item {
    /* padding: 0 12px; */
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      dispaly: flex;
      justify-content: center;
      align-items: center;

      &.left {
        transform: rotate(-90deg);
      }

      &.right {
        transform: rotate(90deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
`;
