import dayjs from "dayjs"
import styled from "styled-components"

type HeaderProps = {
  title: string;
  createdAt: string;
  image: string
}

export const PostHeader:React.FC<HeaderProps> = ({title, createdAt, image}) => {
  const date = dayjs(createdAt).format('dddd, MMMM, D, YYYY')
  return (
    <CardHeader>
      <h1>{title}</h1>
      <Date>Published on {date}</Date>
    </CardHeader>
  )
}

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const Date = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
  margin-left: 10px;
`