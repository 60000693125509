export const Resources = () => {
  return (
    <>
      <h1>Resources for You</h1>

      <p>
        The following is a list of resources that you and your family can refer
        to for more information and general support for obesity and weight
        related issues. This information is to inform and guide you but does not
        replace the opinion or direction of a doctor or professional.
      </p>

      <p>
        <strong>
          <a
            href="http://www.obesityaction.org"
            target="_blank"
            rel="noreferrer"
          >
            OAC – Obesity Action Coalition
          </a>
        </strong>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        The Obesity Action Coalition (OAC) is the ONLY non-profit organization
        whose sole focus is representing individuals affected by obesity.
        Founded in 2005, the OAC remains at the forefront of the fight against
        obesity. From advocating on capitol hill for access to obesity
        \treatments to publishing hundreds of educational resources for
        individuals affected, the OAC truly represents the voice of all those
        affected by obesity.
      </p>

      <p>
        <strong>
          <a
            href="http://www.cdc.gov/obesity/data/adult.html"
            target="_blank"
            rel="noreferrer"
          >
            Center for Disease Control &amp; Prevention
          </a>
        </strong>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        Defines Obesity and weight issues and offers data and statistics as well
        as causes and consequences to the disease. You will also find
        information on programs and additional resources.
      </p>

      <p>
        <strong>
          <a
            href="http://www.aap.org/en-us/Pages/Default.aspx"
            target="_blank"
            rel="noreferrer"
          >
            American Academy of Pediatrics
          </a>
        </strong>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        Specific information for the prevention and treatment of obesity in
        children.
      </p>

      <p>
        <strong>
          <a
            href="http://obesityinamerica.org"
            target="_blank"
            rel="noreferrer"
          >
            Obesity in America
          </a>
        </strong>
      </p>
      <p style={{ paddingLeft: "30px" }}>
        Important News and up to date information about obesity, the science and
        treatment from experts as well as success stories.
      </p>

      <p>
        <strong>
          <a
            href="http://www.realize.com/gastric-bypass?gclid=CK-zsqKyz74CFaQF7AodmEgAiw"
            target="_blank"
            rel="noreferrer"
          >
            Gastric Bypass Surgery
          </a>
        </strong>
      </p>

      <p style={{ paddingLeft: "30px" }}>
        Visit
        <a
          href="http://www.realize.com/gastric-bypass?gclid=CK-zsqKyz74CFaQF7AodmEgAiw"
          target="_blank"
          rel="noreferrer"
        >
          Realize.com
        </a>
        to learn more about Gastric Bypass Surgery. You will find photos, links
        and helpful information about what to expect and why it is done and how
        well it works.
      </p>
    </>
  );
};
