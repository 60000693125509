import { useRef, useEffect, useCallback } from "react";

export function useTimeout(callback: Function, delay?: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<any>();

  // set callback function to ref
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // timeout actions
  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    if (!timeoutRef.current) return;
    clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return () => clear();
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return {
    reset,
    clear,
  };
}
