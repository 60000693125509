import { Modal, TextInput } from "@mantine/core";
import RichTextEditor from "@mantine/rte";
import { useCallback, useState } from "react";
import { CSSTransition } from "react-transition-group";
import Loader from "src/components/Loader";
import { uploadFile } from "src/hooks/useUploadFile";
import { submitSubject } from "src/services";
import { up } from "src/styles/breakpoints";
import styled from "styled-components";
import { htmlToSlateAST } from "@graphcms/html-to-slate-ast";
import { toast, ToastContainer } from "react-toastify";
import { CreateNewPost } from "./components/CreateNewPost";
import { SubjectList } from "./components/SubjectList";

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #361534;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 248px;
  max-height: 48px;
  min-height: 48px;
  flex: 1;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }

  ${up("xlg")} {
    max-width: 326px;
    max-height: 74px;
    min-height: 74px;
    font-size: 18px;
  }
`;

export const CanWeTalk = () => {
  const [opened, setOpened] = useState(false);

  return (
    <Root>
      <Header>
        <div className="col">
          <h1>Can we talk?</h1>
          <h2>Feel Free to ask questions, express your opinions and concerns.</h2>
        </div>

        <StyledButton onClick={() => setOpened(true)}>
          Create New Subject
        </StyledButton>
      </Header>

      {/* HERE WILL BE LIST OF POSTS */}
      <Content>
        <SubjectList />
        <CreateNewPost modalStatus={opened} handleToggleModal={setOpened} />
      </Content>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Root>
  );
};
