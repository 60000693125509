import { useLocation } from "react-router-dom";
import styled from "styled-components";
import parse from "html-react-parser";

import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSubject } from "src/hooks/useSubject";
import { PostComments } from "../BlogPost/components/PostComments";
import { SubjectHeader } from "./components/SubjectHeader";

export const SubjectPage: React.FC = () => {
  const { pathname } = useLocation();
  const id = pathname.split("/can-we-talk/")[1];
  const { data, isLoading } = useSubject(id);

  useEffect(() => {
    const source = document.querySelector("source");
    if (!source) return;
    source.type = "video/mp4";
  });

  if (!isLoading && !data) {
    return <h1>Page Not Found</h1>;
  }
  return (
    <BlogRoot>
      {data && (
        <BlogCard>
          <SubjectHeader
            title={data.subject}
            createdAt={data.publishedAt}
            authorEmail={data.authorEmail}
            authorName={data.authorName}
            
          />
          <BlogContent>
            <div>{parse(data?.content.html || "")}</div>
            <PostComments  id={data.id} />
          </BlogContent>
        </BlogCard>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BlogRoot>
  );
};

const BlogRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 900px;
  /* min-width: 600px; */
`;

const BlogCard = styled.div`
  display: flex;
  flex-direction: column;
`;
const BlogContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);

  img {
    height: auto !important;
  }

  img,
  video {
    max-width: 100%;
    margin-block: 10px;
  }

  .center {
    display: flex;
    justify-content: center;
  }
  .left {
    display: flex;
    justify-content: flex-start;
  }
  .right {
    display: flex;
    justify-content: flex-end;
  }
`;
