import { Footer } from "src/components/Footer";
import { down, up } from "src/styles/breakpoints";
import styled from "styled-components";
import { Header } from "./Header";

const Layout: React.FC = ({ children }) => {
  return (
    <Root>
      <Header />
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
      <Footer />
    </Root>
  );
};

export default Layout;

// styles
export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1 0 auto;
  ${down("md")} {
    flex-wrap: wrap;
  }

  padding: 0 20px;
  gap: 20px;

  ${up("sm")} {
    padding: 0 40px;
  }

  ${up("md")} {
    padding: 0 60px;
    gap: 60px;
    max-width: 1140px;
  }

  ${up("lg")} {
    max-width: 1300px;
  }

  ${up("xlg")} {
    max-width: 1340px;
    min-width: 1180px;
    padding: 0 128px;
  }

  ${up("xl")} {
    padding: 0 144px;
    gap: 100px;
    max-width: 1600px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000000;
  margin-top: 40px;

  h1,
  h2 {
    text-transform: capitalize;
    font-weight: normal;
    line-height: 1.5;
    /* margin-block: 20px; */
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 28px;
    /* margin-top: 20px; */
  }

  p {
    text-align: left;
    line-height: 1.5;
  }

  a {
    color: #07a5dc;
  }

  blockquote {
    background: #fff;
    color: #000;
    border-left: 4px solid #361534;
    padding: 15px;
  }

  img {
    max-width: 100%;
  }

  ${up("sm")} {
    blockquote {
      display: flex;
      flex-direction: column;
      /* width: 100%; */
    }
  }

  ${up("xlg")} {
    margin-top: 80px;
    h1 {
      font-size: 60px;
    }

    h2 {
      font-size: 38px;
    }
  }
`;
