import { useQuery } from "react-query";
import { IPosts } from "src/interfaces/post";
import { fetchPosts } from "src/services";

export const usePosts = (limit: number, skip: number) => {
  const { data, refetch, isFetched } = useQuery<IPosts>(
    "POSTS",
    () => fetchPosts(limit, skip),
    { retry: 0 }
  );

  return { data, refetch, isFetched };
};
