import styled from "styled-components";
import { PressImages1 } from "./constants";

const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  img {
    width: 150px;
    height: 150px;
    aspect-ratio: 1/1;
    margin: 8px;
    object-fit: cover;
  }

`;

export const Press = () => {
  return (
    <>
      <h1>Press</h1>
      <p>
        Dana has appeared on Fox, The Dr. Oz show, TED-X, and The Today Show.
      </p>

      <Photos>
        {PressImages1.map((img, index) => (
          <a href={img} key={img}>
            <img src={img} alt={`press-${index}`} />
          </a>
        ))}
        <br />
      </Photos>
    </>
  );
};
