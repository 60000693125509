import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { up } from "src/styles/breakpoints";
import styled from "styled-components";
import { navItems } from "./navItems";

const MenuWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 10px 60px;
  padding: 0;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    li {
      position: relative;
    }
    .main {
      li {
        position: relative;
        font-family: "Roboto";
        color: rgba(255, 255, 255, 0.6);
        transition: 0.2s ease-in-out;
        font-size: 18px;
        padding-block: 15px;

        &::after {
          content: "";
          display: flex;
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          background-color: #fff;
          max-width: 0;
          transition: 0.2s;
        }

        &.selected,
        &:hover {
          color: rgba(255, 255, 255, 1);
          &::after {
            max-width: 100%;
          }
          /* border-bottom: 2px solid #fff; */
        }
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    .sub-items {
      position: relative;
      font-family: "Roboto";
      color: rgba(255, 255, 255, 0.6);
      transition: 0.2s ease-in-out;
      font-size: 18px;
      padding-block: 15px;

      &.selected,
      &:hover {
        color: rgba(255, 255, 255, 1);
        &::after {
          max-width: 100%;
        }
        /* border-bottom: 2px solid #fff; */
      }
    }
  }

  ${up("xlg")} {
    margin: 30px 140px;
  }
`;

const SubMenu = styled.ul`
  width: 170px;
  position: absolute;
  top: calc(100% + 12px);
  display: flex;
  flex-direction: column;
  z-index: 100;
  /* border-top: 3px solid #ab8b65; */
  background-color: #e5e5e5;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s;

  li {
    position: relative;
    font-family: "Roboto";
    color: #000000;
    transition: 0.2s ease-in-out;
    font-size: 14px;
    padding: 6px 20px;
    border-bottom: 1px solid #20103650;
    &:last-of-type {
      border: none;
    }

    &.selected {
      background-color: #fff;
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
    transition: 0.2s;
  }
`;

export const MainNavigation = () => {
  const [selected, setSelected] = useState<any>(null);
  const {pathname} = useLocation();


  // useEffect

  return (
    <MenuWrapper>
      <div className="row">
        {navItems.map((navItem) =>
          !navItem.items ? (
            <div className="main" key={navItem.name}>
              <li
                className={
                  pathname === navItem.link ? "selected" : ""
                }
              >
                <Link to={navItem.link}>{navItem.name}</Link>
              </li>
            </div>
          ) : (
            <li
              onMouseEnter={() => setSelected(navItem.id)}
              onMouseLeave={() => setSelected(null)}
              key={navItem.name}
            >
              <Link className="sub-items" to="#">
                {navItem.name}
              </Link>
              {/* <SubMenu className={'active'}> */}
              <SubMenu className={selected === navItem.id ? "active" : ""}>
                {navItem.items.map((subItem) => (
                  <li
                    className={
                      pathname === subItem.link
                        ? "selected"
                        : ""
                    }
                    key={subItem.name}
                  >
                    <Link to={subItem.link}>{subItem.name}</Link>
                  </li>
                ))}
              </SubMenu>
            </li>
          )
        )}
      </div>
    </MenuWrapper>
  );
};
