import {useCallback, useEffect, useState} from "react";

export const useLocalStorage = () => {
    const [isDisclaimer, setIsDisclaimer] = useState(true)

    useEffect(() => {
        const item = localStorage.getItem('disclaimerAccepted')
        if(!item) {
            console.log(`no disclaimerAccepted`)
            setIsDisclaimer(false)
        } else {
            console.log(`disclaimer accepted`)
            setIsDisclaimer(true)
        }
    }, [isDisclaimer, setIsDisclaimer])

    const AcceptDisclaimer = useCallback(() => {
        if(isDisclaimer) return
        localStorage.setItem('disclaimerAccepted', JSON.stringify(true))
        setIsDisclaimer(true)
    }, [setIsDisclaimer])

    return {
        isDisclaimer,
        AcceptDisclaimer
    }
}