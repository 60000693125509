
export const navItems = [
  {
    id: 0,
    name: "Home",
    link: "/"
  },
  {
    id: 1,
    name: "About",
    items: [
      {
        name: "Facing Obesity",
        link: "/facing-obesity"
      },
      {
        name: "Dana Rosser",
        link: "/my-story"
      }
    ]
  },
  {
    id: 2,
    name: "About the Book",
    link: "/About-Book"
  },
  {
    id: 3,
    name: "Press",
    link: "/press"
  },
  {
    id: 4,
    name: "Bio",
    link: "/bio"
  },
  {
    id: 5,
    name: "Resources",
    link: "/resources"
  },
  {
    id: 6,
    name: "Blog",
    link: "/blog",
  },
  {
    id: 7,
    name: "Can we talk?",
    link: "/can-we-talk",
  },

  {
    id: 8,
    name: "Contact Us",
    link: "/contact-us"
  },
]