import htmlToSlateAST from "@graphcms/html-to-slate-ast";
import { Modal } from "@mantine/core";
import RichTextEditor from "@mantine/rte";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import Loader from "src/components/Loader";
import { uploadFile } from "src/hooks/useUploadFile";
import { submitSubject } from "src/services";
import { up } from "src/styles/breakpoints";
import styled from "styled-components";

type CreatePostTypes = {
    modalStatus: boolean;
    handleToggleModal: (status:boolean) => void;
}

const StyledForm = styled.form`
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #361534;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 248px;
  max-height: 48px;
  min-height: 48px;
  flex: 1;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }

  ${up("xlg")} {
    margin-top: 45px;
    max-width: 326px;
    max-height: 74px;
    min-height: 74px;
    font-size: 18px;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block: 10px;
  input,
  textarea {
    font-family: "Montserrat";
    font-weight: 500;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    max-width: 790px;
    border: 1px solid #d2d2d2;
    background: rgba(0, 0, 0, 0.1);
    margin-block: 4px;
    font-size: 13px;
    color: #747474;
    padding-inline: 26px;
    float: left;
    margin-right: 1%;
    outline: none;
  }
`;

// RICH EDIT DATA
const availableControls: any = [
  ["bold", "italic", "underline", "link"],
  ["unorderedList", "h1", "h2", "h3"],
  ["sup", "sub"],
  ["alignLeft", "alignCenter", "alignRight"],
];

const initialValue =
  "<p>Your initial <b>html value</b> or an empty string to init editor without value</p>";

export const CreateNewPost:React.FC<CreatePostTypes> = ({modalStatus, handleToggleModal }) => {
  const [content, setContent] = useState(initialValue);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = useCallback(
    (data) => {
      setContent(data);
    },
    [setContent]
  );

  const handleSubmit = useCallback(
    async (e: React.SyntheticEvent) => {
      setSubmitting(true);
      e.preventDefault();

      const target = e.target as typeof e.target & {
        name: { value: string };
        email: { value: string };
        subject: { value: string };
        primaryImage: { files: any };
        content: { value: string };
      };

      const name = target.name.value;
      const email = target.email.value;
      const subject = target.subject.value;
      const primaryImage = target.primaryImage.files[0];


      if (!primaryImage || !name || !email || !subject || !content.replace(/(<([^>]+)>)/gi, "")) {
        toast.error("Validation error");
        setSubmitting(false);
        return;
      }

      const uploadedImage = await uploadFile(primaryImage).then((e) => {
        return e;
      });

      const parsedContent = { children: await htmlToSlateAST(content) };

      const payload = {
        name,
        email,
        uploadedImage,
        subject,
        parsedContent,
      };

      submitSubject(payload)
        .then((e) => {
          toast.success("Subject successfuly send to review");
          setSubmitting(false);
        })
        .catch((e) => {
          toast.error(e);
          setSubmitting(false);
        });
    },
    [setSubmitting, htmlToSlateAST, content]
  );

  return (
    <>
      <Modal
        style={{ zIndex: 10000 }}
        size="xl"
        centered
        opened={modalStatus}
        onClose={() => handleToggleModal(false)}
        title={<h1>Create new subject</h1>}
      >
        <StyledForm onSubmit={handleSubmit}>
          <FormField>
            <h4>Name</h4>
            <input placeholder="Your Name *" type="text" name="name" />
          </FormField>
          <FormField>
            <h4>Email</h4>
            <input placeholder="Your Email  *" type="email" name="email" />
          </FormField>
          <h4>Primary Image</h4>
          <input
            placeholder="Primary Image *"
            type="file"
            name="primaryImage"
          />
          <FormField>
            <h4>Subject</h4>
            <input placeholder="Subject *" type="text" name="subject" />
          </FormField>
          <h4>Content</h4>
          <RichTextEditor
            controls={availableControls}
            value={content}
            onChange={handleChange}
          />
          <StyledButton type="submit">Send</StyledButton>
        </StyledForm>
      </Modal>

      <CSSTransition in={submitting} timeout={200} unmountOnExit>
        <Loader
          text={"<span>Please Wait. <br/> Your subject is submitting</span>"}
        />
      </CSSTransition>
    </>
  );
};
