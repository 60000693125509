import dayjs from "dayjs";
import { IComment } from "src/interfaces/post";
import styled from "styled-components";

type componentProps = {
  comment: IComment;
};

export const Comment: React.FC<componentProps> = ({ comment }) => {
  const date = dayjs(comment.createdAt).format("dddd, MMMM, D, YYYY");
  return (
    <CommentItem>
      <ComponentHeader>
        <div className="row">
          <Author>
            <AuthorName>{comment.authorName}</AuthorName>
            <AuthorEmail href={`mailto:${comment.authorEmail}`}>
              {comment.authorEmail}
            </AuthorEmail>
          </Author>
          <Date>{date}</Date>
        </div>
      </ComponentHeader>
      <Message>
        <p>{comment.message}</p>
      </Message>
    </CommentItem>
  );
};

const CommentItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-block: 8px;
`;

const ComponentHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  .row {
    display: flex;
    justify-content: space-between;
  }
`;

const Author = styled.div`
  display: flex;
  flex-direction: column;
`;

const AuthorName = styled.h4`
  font-weight: 500;
`;

const AuthorEmail = styled.a`
  color: #07a5dc;
`;

const Message = styled.div`
  display: flex;
  padding-top: 10px;
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

const Date = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
`;
