import styled from "styled-components"
import DanaRosserBook from 'src/images/DanaRosserBook.jpg'
import AmazonLogo from 'src/images/AmazonLogo.png'
import BNLogo from 'src/images/BNLogo.png'
import XulonLogo from 'src/images/XulonLogo.png'


const Row = styled.div`
  display: flex;

`

const Book = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 574px;
  margin: 30px auto;
  padding: 20px;
  text-align: center;
  background-color: #f2f2f2;
  border: 2px dotted #07a5dc;
  .book-img {
    width: 165px;
    margin-right: 15px;
  }

  .book-title {
    text-align: center;
    color: #07a5dc;
    font-weight: bold;
    font-size: 17px;
  }

  .stores-row {
    display: flex;
    margin-top: 15px;
    a {
      margin-inline: auto;
    }
    img {
      height: 35px;
    }
  }

`


export const BuyBook = () => {
  return (
    <Book>
      <Row>
        <img className="book-img" src={DanaRosserBook} alt="" />
        <div>
          <p className="book-title">Ready for a change?</p>
          <p>
            I get to the heart of the issues facing the loved ones of those fighting the weight loss battle in my NEW book
            <br />
            <strong><em>“Thru Thick and Thin:<br />
              Facing Obesity Thru the Eyes of a Loved One”</em></strong>
          </p>
        </div>
      </Row>

      <div className="stores-row">
        <a href="http://www.amazon.com/Thru-Thick-Thin-Dana-Rosser/dp/1498446000/ref=sr_1_1" rel="noreferrer" target="_blank">
          <img src={AmazonLogo} alt="" />
        </a>
        <a href="http://www.barnesandnoble.com/w/thru-thick-thin-dana-m-rosser/1122870632?ean=2940151016131" rel="noreferrer" target="_blank">
          <img src={BNLogo} alt="" />
        </a>
        <a href="http://www.xulonpress.com/bookstore/bookdetail.php?PB_ISBN=9781498446006&amp;HC_ISBN=9781498454995" rel="noreferrer" target="_blank">
          <img src={XulonLogo} alt="" />
        </a>
      </div>
    </Book>
  )
}