import DanaTed from "src/images/Dan-TED.jpg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { up } from "src/styles/breakpoints";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${up('sm')} {
    display: block;
  }
`

const Atachment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  float: right;
  padding: 36px;

  ${up('sm')} {
    padding: 0 0 36px 36px;
  }
  img {
    width: 100%;
    box-shadow: -18px 18px 0px 5px rgba(38, 17, 53, 0.1);
  }
`;

export const FacingObesity = () => {
  return (
    <Root>
      <h1>About Facing Obesity</h1>
      <Atachment>
        <img src={DanaTed} alt="" />
      </Atachment>
      <p>
        Facing Obesity is a site dedicated to the highly sensitive issue of
        obesity. Author and expert Dana M. Rosser, openly shares and offers a
        voice for the unique challenges faced by those who are married or family
        member/friends of a loved one, who suffers from this disease.
      </p>

      <p>
        Dana, is the wife of Dr. James ”Butch” Rosser, a world renowned surgeon
        who openly discusses his battle with severe obesity, together they have
        traveled the world to empower and educate people about the effects of
        obesity. As his wife and a writer, she realized, that the spouse or
        family member’s voice is often overlooked. Her mission is to create
        dialogue, and a community, that is encouraging for spouses and loved
        ones, where support is given, and ideas are shared. Dana’s honest and
        candid approach offers better understanding and communication, helping
        to cope with the day–to-day struggles, without judgment or fear. Through
        her work and efforts, she hopes to provide a safe space that helps
        families and loved ones, conquer the unique obstacles they face, which
        will help contribute to healthier lifestyles and relationships.
      </p>
      <p>
        Dana Rosser resides in Orlando, Florida with her husband Dr. James
        “Butch” Rosser Jr. and are the parents of five children; Kevin, Duane,
        Nicole and twins Taylor and Tianna. They also have two beautiful
        grandchildren, Easton and August. A native of Akron, Ohio and a graduate
        of The University of Akron, Dana enjoys, cooking, reading, running,
        writing and traveling as a few of her favorite pastimes.
      </p>

      <p>
        <strong>
          Author and expert Dana Rosser can be reached for&nbsp;interviews or
          speaking engagements to share her unique perspective and insight into
          obesity and weight issues.
          <Link title="Contact Us" to="/contact-us">
            Click Here
          </Link>
        </strong>
      </p>
    </Root>
  );
};
