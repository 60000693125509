import BookImg from "src/images/DanaRosserBook.jpg";
import WorkbookImg from "src/images/DanaRosserWorkbook.jpg";
import BookEspImg from "src/images/BookEsp.jpg";
import BookEspImg2 from "src/images/BookEsp2.jpg";
import styled from "styled-components";
import { BuyBook } from "src/components/BuyBook";
import { useCallback, useEffect, useState } from "react";
import { Modal, Select } from "@mantine/core";
import { up } from "src/styles/breakpoints";
import { init } from "@emailjs/browser";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import Loader from "src/components/Loader";
import "react-toastify/dist/ReactToastify.css";

const ImagesRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  img {
    width: 100%;
    max-width: 207px !important;
    aspect-ratio: 2 / 3;
    object-fit: contain;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  select {
    display: flex;
    height: fit-content;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 0px;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background-color: #361534;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 248px;
  max-height: 48px;
  min-height: 48px;
  flex: 1;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }

  ${up("xlg")} {
    margin-top: 45px;
    max-width: 326px;
    max-height: 74px;
    min-height: 74px;
    font-size: 18px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    max-width: 100%;
    width: 100% !important;
    margin: 0;
    margin-top: 20px;
    max-height: 48px;
    min-height: 48px;
  }
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-block: 10px;
  input,
  textarea {
    font-family: "Montserrat";
    font-weight: 500;
    height: 48px;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    max-width: 790px;
    border: 1px solid #d2d2d2;
    background: rgba(0, 0, 0, 0.1);
    margin-block: 4px;
    font-size: 13px;
    color: #747474;
    padding-inline: 26px;
    float: left;
    margin-right: 1%;
    outline: none;
  }
`;

const Image = styled.div``;

export const AboutBook = () => {
  const [translation, setTranslation] = useState("eng");
  const [opened, setOpened] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  console.log(translation);

  useEffect(() => {
    init("user_iAXGObJwMu2Q3BUFbDZZQ");
  }, [init]);

  const handleSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      setSubmitting(true);
      e.preventDefault();

      const target = e.target as typeof e.target & {
        email: { value: string };
        name: { value: string };
        subject: { value: string };
        message: { value: string };
      };
      const email = target.email.value;
      const name = target.name.value;
      const subject = "Buy the book";
      const message = "I want to buy a Spanish translation of the book.";
      if (!email || !name) {
        toast.error(
          "Validation errors occurred. Please confirm the fields and submit it again."
        );
        setSubmitting(false);
        return;
      }

      emailjs
        .send("service_e146fyp", "template_wqo13sy", {
          message: message,
          email: email,
          subject: subject,
          name: name,
        })
        .then(() => {
          setSubmitting(false);

          toast.success("Your message was sent successfully. Thanks.");
          setOpened(false);
        })
        .catch((error) => setSubmitting(false));
    },
    [toast]
  );

  return (
    <>
      <Header>
        <h1>About The Book</h1>
      </Header>
      <p>English Translation:</p>
      <ImagesRow>
        <a
          href="https://www.amazon.com/Thru-Thick-Thin-Dana-Rosser/dp/1498454992/ref=sr_1_1?qid=1644762109&refinements=p_27%3ADana+M.+Rosser&s=books&sr=1-1&text=Dana+M.+Rosser"
          target="_blank"
        >
          <img src={BookImg} alt="" />
        </a>
        <a
          href="https://www.amazon.com/Thru-Thick-Thin-Dana-Rosser/dp/1983703125/ref=sr_1_2?qid=1644761635&refinements=p_27%3ADana+M.+Rosser&s=books&sr=1-2&text=Dana+M.+Rosser"
          target="_blank"
        >
          <img src={WorkbookImg} alt="" />
        </a>
      </ImagesRow>
      <p>Spanish Translation:</p>
      <ImagesRow>
        <Image>
          <img src={BookEspImg} />
        </Image>
        <Image>
          <img src={BookEspImg2} />
        </Image>
      </ImagesRow>
      <StyledButton onClick={() => setOpened(true)}>Buy Spanish Translation</StyledButton>
      <h1>Description</h1>
      <p>
        The disease of obesity is public enemy number one. Over 138 million
        adults in US suffer with obesity. This assassin of quality and longevity
        of life has proven an elusive adversary. Focus has been mainly directed
        toward the treatment of individuals who are stricken with this disease.
        But there is a clan of silent sufferers whose well-being and struggle go
        unnoticed—the loved ones.
      </p>
      <p>
        First-time author, Dana M. Rosser, gets to the heart of the issues
        facing the loved ones of those fighting the weight loss battle in her
        debut book{" "}
        <i>
          <b>
            "Thru Thick and Thin: Facing Obesity Thru the Eyes of a Loved On"
          </b>
        </i>
      </p>
      <p>
        Dana recounts her personal journey of supporting her husband, a world-
        renowned surgeon, who once weighed over 460 lbs. With vivid detail, she
        chronicles the breakdown that led to a miraculous breakthrough. “Thru
        Thick and Thin” provides the reader with a shoulder to cry on, while
        also offering insight and clarity into a world most don’t know exist.
      </p>
      <p>
        <strong>ENDORSEMENTS</strong>
      </p>
      <blockquote>
        <p>
          “Thru Thick &amp; Thin” is a story of triumph over severe obesity and
          the arduous journey of both patient and family members. Anyone who
          struggles with obesity or cares deeply for a loved one with obesity
          should read this book!”
          <br />– Philip Schauer, M.D., Professor of Surgery, Cleveland Clinic
          Lerner College of Medicine, Cleveland, Ohio
        </p>
        <p>
          “The key to overcoming any challenge is education and enlightenment.
          As my good friend, Dr. Butch Rosser, would say. “You don’t know, what
          you don’t know and what you don’t know can cause you to fail.” This
          landmark book from his wife, Dana Rosser, provides critical
          information for those who dare to love the obese. This book is a must
          read.”
          <br />– Rod Paige, Ph.D., Former U.S.Secretary of Education, (January
          20, 2001-January 20, 2005)
        </p>
      </blockquote>
      <BuyBook />

      {/* MODAL */}

      <Modal
        style={{ zIndex: 10000 }}
        size="xs"
        centered
        opened={opened}
        onClose={() => setOpened(false)}
        title="Buy the book"
      >
        <StyledForm onSubmit={handleSubmit}>
          <FormField>
            <input placeholder="Your Name *" type="text" name="name" />
          </FormField>
          <FormField>
            <input placeholder="Your Email  *" type="email" name="email" />
          </FormField>
          <StyledButton type="submit">Send</StyledButton>
        </StyledForm>
        {/* Modal content */}
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <CSSTransition in={submitting} timeout={200} unmountOnExit>
        <Loader
          text={"<span>Please Wait. <br/> Your message is submitting</span>"}
        />
      </CSSTransition>
    </>
  );
};
