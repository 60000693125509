import { useQuery } from "react-query";
import { IComment, IPost } from "src/interfaces/post";
import { fetchComments, fetchPost } from "src/services";

export const usePost = (slug: string) => {
  const { data, isLoading } = useQuery<IPost>("BLOG_POST", () => fetchPost(slug));

  return {
    data,
    isLoading
  };
};

export const useComments = (chatId: string) => {
  const { data, refetch } = useQuery<IComment[]>(
    `${chatId.toUpperCase()}_COMMENTS`,
    () => fetchComments(chatId)
  );

  return { data, refetch };
};
