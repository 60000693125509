import React from "react";
// import "src/styles/loader.css";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;

  .loader {
    height: 32px;
    width: 32px;
    position: relative;
    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: 32px;
        width: 32px;
        border: 2px solid ${(props) => props.theme.textColor};
        border-radius: 50%;
        opacity: 0;
        /* -webkit-animation: loader-6-2 1.5s cubic-bezier(0.075, 0.820, 0.165, 1.000) .25s infinite; */
        animation: loader-6-2 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s
          infinite;
      }
    }

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      border: 2px solid ${(props) => props.theme.textColor};
      border-radius: 50%;
      opacity: 0;
      -webkit-animation: loader-6-1 1.5s cubic-bezier(0.075, 0.82, 0.165, 1)
        infinite;
      animation: loader-6-1 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    }
  }

  @keyframes loader-6-1 {
    0% {
      transform: translate3d(0, 0, 0) scale(0);
      opacity: 1;
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1.5);
      opacity: 0;
    }
  }

  @keyframes loader-6-2 {
    0% {
      transform: translate3d(0, 0, 0) scale(0);
      opacity: 1;
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      opacity: 0;
    }
  }

  .loader-text {
    margin-top: 30px;
    text-align: center;
    color: #000;
  }
`;
type ComponentProps = {
  text: string;
};

const Loader = ({ text }: ComponentProps) => {
  return (
    <LoaderWrapper>
      <div className="loader">
        <span></span>
      </div>
      <div className="loader-text" dangerouslySetInnerHTML={{ __html: text }} />
    </LoaderWrapper>
  );
};

export default Loader;
