import React, { useEffect, useState } from "react"
import styled from "styled-components";
import { ArrowUpIcon } from "./svg";


const StyledButton = styled.button`
  position: fixed;
  right: 20px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  background: #E5E5E5;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 48px;
  height: 35px;
  border: none;
  border-radius: 4px 4px 0 0;
  color: #fff;
  opacity: 0.9;
  z-index: 100;
  transition: 0.3s;
  span {
    width: 14px;
    height: 2px;
    border-radius: 4px;
    margin-top: 4px;
  }
  svg {
    fill: #ffffff;
  }

`

export const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <>
      {isVisible &&
        <StyledButton onClick={scrollToTop}>
          <ArrowUpIcon height="18"/>
        </StyledButton>
      }

    </>
  )
}
