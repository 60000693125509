import { useQuery } from "react-query";
import {  fetchPost, fetchSubject } from "src/services";
import { ISubject } from "./useSubjects";


export const useSubject = (id: string) => {
  const { data, isLoading } = useQuery<ISubject>("SUBJECT_POST", () => fetchSubject(id));

  return {
    data,
    isLoading
  };
};

