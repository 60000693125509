import DanaRosserImg from "src/images/Dana12.png";
import styled from "styled-components";
import { up } from "src/styles/breakpoints";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${up("sm")} {
    display: block;
  }
`;

const Atachment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  float: right;
  padding: 36px;

  ${up("sm")} {
    padding: 0 0 36px 36px;
  }
  img {
    width: 100%;
    box-shadow: -18px 18px 0px 5px rgba(38, 17, 53, 0.1);
    margin-bottom: 40px;
  }

  p {
    margin-bottom: 0;
  }
`;

export const DanaStory = () => {
  return (
    <Root>
      <h1>Dana’s Story</h1>
      <Atachment>
        <img src={DanaRosserImg} alt="" />
        <p>Dana Rosser</p>
      </Atachment>
      <p>
        After graduating from college with a marketing degree and landing a
        successful job, I thought I had a handle on most things in life. I came
        from a good family. I had friends, a good church with a bright future. I
        was leading a relatively good life. In 1992, I met the man of my dreams,
        James “Butch” Rosser, MD, a successful surgeon. I absolutely fell for
        him. Butch was from Mississippi and he had that “southern thang” going
        on, and I loved it. His smile was contagious and his heart was like no
        other, so for the first time in my life I felt like there was someone in
        the world who was just for me. Butch was the one. Oh by the way I forgot
        to mention that Butch stood 6’4” tall and weighed 460 pounds. Yes he was
        classified as being severely obese. Forgetting that fact was impossible,
        because that was the attribute on which everyone seemed to fixate on
        during our courtship
      </p>
      <p>
        As Butch and I started to date seriously, people were accusing me of
        being with him because he was a surgeon, because of course why else
        would a seemingly beautiful young lady (that is what I was told) would
        want with a severely obese man… what could I possibly see in him? That
        was my introduction to Butch’s everyday world of dealing with
        judgmental, insensitive people in which I grew to despise.
      </p>

      <p>
        Despite the naysayers Butch and I dated for three years and wed on Dec.
        9, 1995. Make no mistake I was not burying my head in the sand about
        Butch’s weight. I knew the seriousness of carrying that much weight and
        the possible co-morbidities (diabetes, hypertension, heart issues, etc.)
        that went along with it. It took a lot of soul searching on my part to
        really divulge my true feelings about Butch’s weight and how it affected
        me. On one hand I wanted to support and protect him from this mean cruel
        world of people who discriminated against the “nutritionally challenged”
        but on the other hand I needed to talk with someone about how his weight
        affected me. I remember worrying on a daily basis that I would get a
        call from the hospital telling me that my husband had a heart attack… I
        lived in fear of losing him daily. Another “challenge” was the
        limitations that were put on our lives. There were times that I would
        like to plan simple outings for most people however, in our family it
        would take great patience on my part to accomplish the task. For
        example, going to the movies, theatre, and out to dinner became a major
        undertaking. I had to make sure each venue had appropriate seating for
        Butch. Because of this Butch at times seem to be resistant to going out
        because of the fear of the unknown variables. For Butch home was a safe
        haven because there was no one there to judge him or to constantly
        illuminate his struggle. Needless to say, more often than not we just
        stayed at home which really depressed me… I lost Dana.
      </p>

      <p>
        I became angry and frustrated at times of feeling deprived. Deprived
        from doing “normal” things that most couples/families do. But how do you
        communicate this to your spouse? You know that they know they have a
        weight problem, so why would you dare bring these things up to them and
        make them feel even worse. You are supposed to be the strong one and the
        one that protects them from this evil world. Right? Wrong!!!! I wish
        someone had told me not to neglect my feelings in order to protect my
        loved one. Now I am not suggesting that you insult, berate or talk down
        to your loved one at all, I am merely suggesting to communicate in a
        loving manner how you feel about how certain situations affect you .
        Once I had the courage to talk to Butch openly and honestly, he was
        shocked at how his obesity challenge affected me daily… he had no idea.
      </p>

      <p>
        In researching this subject, I was very surprised to find that there
        were no support groups to assist me with my unique challenges. There are
        family support groups such as Al-anon that offer hope and help to
        families and friends of alcoholics but nothing for families or friends
        whose loved ones suffer with the disease of obesity.
      </p>

      <p>
        Because I needed support, I wanted to create a non-judgmental (forum)
        website (facingobesity.com) that will encourage loved ones to express
        their feelings and emotions freely. By having positive dialogue, I am
        hoping in some small way to heal the hurts of an extraordinary group of
        people that have sometimes been forgotten and are an intricate part of
        the healing process in assisting their loved ones to a healthier
        lifestyle.
      </p>
    </Root>
  );
};
