import styled from "styled-components";
import PictureOfDanaRosser from "src/images/PictureOfDanaRosser.jpg";
import { HomeCarousel } from "src/components/Carousel/HomeCarousel";
import { up } from "src/styles/breakpoints";

const Stats = styled.div`
  display: flex;
  margin-block: 20px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;

  ${up("sm")} {
    justify-content: space-between;
  }
`;

const StatBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 240px;

  h3,
  h4 {
    font-weight: 400;
    line-height: 1.6;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 24px;
  }

  ${up("lg")} {
    max-width: 310px;

    h3 {
      font-size: 34px;
    }

    h4 {
      font-size: 28px;
    }
  }
`;

const Ammount = styled.div`
  width: 148px;
  height: 148px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #361534;
  margin-bottom: 12px;

  h1,
  h2 {
    margin: 0 !important;
    color: #f1e5e5;
  }
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 26px;
  }

  ${up("lg")} {
    width: 156px;
    height: 156px;
  }

  ${up("xlg")} {
    width: 200px;
    height: 200px;

    h1 {
      font-size: 80px;
    }

    h2 {
      font-size: 34px;
    }
  }
`;

const Welcome = styled.div`
  display: flex;
  line-height: 30px;
  margin-block: 40px;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;

  .col {
    display: flex;
    flex-direction: column;
  }

  h5 {
    font-weight: normal;
    font-size: 28px;
  }

  ${up("md")} {
    flex-wrap: nowrap;
    .col {
      width: 50%;
    }
  }

  ${up("lg")} {
    gap: 80px;
  }
`;

const Atachment = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 534px;
  width: 100%;
  margin-inline: 20px;
  img {
    box-shadow: -18px 18px 0px 5px rgba(38, 17, 53, 0.1);
    margin-bottom: 20px;
  }

  p {
    margin-top: 20px;
    text-align: left;
  }

  ${up("sm")} {
    margin-inline: 0;
  }

  ${up("md")} {
    width: 50%;
  }
`;

export const HomepageView = () => {
  return (
    <>
      <h1>A letter from our founder</h1>
      <h2>The statistics are staggering…</h2>
      <Stats>
        <StatBlock>
          <Ammount>
            <h1>130</h1>
            <h2>(42.2%)</h2>
          </Ammount>
          <h3>Million People</h3>
          <h4>of Americans are suffering with obesity </h4>
        </StatBlock>

        <StatBlock>
          <Ammount>
            <h1>29</h1>
            <h2>(9.2%)</h2>
          </Ammount>
          <h3>Million People</h3>
          <h4>of Americans are suffering with severe obesity</h4>
        </StatBlock>

        <StatBlock>
          <Ammount>
            <h1>2.8</h1>
          </Ammount>
          <h3>Million Deaths</h3>
          <h4>globally are attributed to obesity each year</h4>
        </StatBlock>
      </Stats>
      <Welcome>
        <Atachment>
          <img src={PictureOfDanaRosser} alt="" />
          <p>Dr. James “Butch” and Dana Rosser</p>
        </Atachment>

        <div className="col">
          <h5>
            The statistic that are not accounted for are the millions of family
            members/supporters who too are affected by the disease of obesity.
          </h5>
          <br />
          <p>
            I created this non-judgmental website to encourage families, loved
            ones and friends to express their feelings and emotions freely about
            living and loving someone who suffers with the disease of Obesity.
            By having a positive dialogue I’m hoping in some small way to heal
            the hurts of an extraordinary group of people that have at times
            been forgotten.
          </p>
          <p>
            <b>Welcome and please contribute freely!</b>
          </p>
          <p className="light">
            Dana Rosser
            <br />
            Founder, FacingObesity.com
          </p>
        </div>
      </Welcome>
      <h1>Latest information</h1>

      <HomeCarousel />
    </>
  );
};
