import styled from "styled-components";
import HeadlineImg from "src/images/dana-header.png";
import useBreakpoint from "src/styles/useBreakpoint";
import { up } from "src/styles/breakpoints";
import { MainNavigation } from "./Navigation/MainNavigation";
import { MobileNavigation } from "./Navigation/MobileNavigation";
import { FaceBookIcon, TwitterIcon } from "src/components/svg";

export const Header = () => {
  const upMd = useBreakpoint(up("md"));
  return (
    <Root>
      {upMd ? <MainNavigation /> : <MobileNavigation />}
      {/* <Link to="/"> */}
      <Headline>
        <HeaderInfo>
          <h1>Facing Obesity</h1>
          <h2>A community of caring</h2>
          <Founder>
            <h3>Dana M. Rosser</h3>
            <h4>Founder</h4>
          </Founder>
          <Socials>
            <a
              href="https://www.facebook.com/FacingObesity/"
              target="_blank"
              rel="noreferrer"
            >
              <FaceBookIcon height="26" />
            </a>
            <a
              href="https://twitter.com/facingobesity"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon height="26" />
            </a>
          </Socials>
        </HeaderInfo>
        <HeaderImg>
          <img src={HeadlineImg} alt="" />
        </HeaderImg>
      </Headline>
      {/* </Link> */}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-block: 10px;
  flex: 0 0 auto;
  background: linear-gradient(
    180deg,
    rgba(54, 21, 52, 1) 50%,
    rgba(0, 128, 128, 1) 100%
  );
  img {
    width: 100%;
    /* max-width: 960px; */
  }
  a {
    margin-bottom: 0;
  }
`;

const Headline = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  gap: 20px;

  ${up("sm")} {
    padding: 0 40px;
  }

  ${up("md")} {
    padding: 0 60px;
    gap: 60px;
    max-width: 1000px;
  }

  ${up("lg")} {
    max-width: 1140px;
  }

  ${up("xlg")} {
    width: 100%;
    max-width: 1480px;
    padding: 0 144px;
  }

  ${up("xl")} {
    gap: 100px;
    max-width: 1600px;
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  color: #fff;

  h1,
  h2 {
    margin: 0;
    font-family: "Roboto";
    line-height: 1.2;
  }

  h1 {
    font-size: 32px;
    text-transform: uppercase;
  }

  h2 {
    font-weight: 400;
    font-size: 18px;
  }

  ${up("sm")} {
    h1 {
      font-size: 48px;
    }

    h2 {
      font-size: 24px;
    }
  }

  ${up("md")} {
    h1 {
      font-size: 60px;
    }

    h2 {
      font-size: 28px;
    }
  }

  ${up("lg")} {
    h1 {
      font-size: 80px;
    }

    h2 {
      font-size: 38px;
    }
  }

  ${up("xl")} {
    h1 {
      font-size: 100px;
    }

    h2 {
      font-size: 48px;
    }
  }
`;

const Founder = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-top: 20px;
  h3,
  h4 {
    margin: 0;
    line-height: 1.6;
  }

  h3 {
    font-weight: 400;
    font-size: 18px;
  }

  h4 {
    font-weight: 300;
    font-size: 16px;
  }

  ${up("sm")} {
    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 20px;
    }
  }

  ${up("md")} {
    margin-top: 40px;
    h3 {
      font-size: 22px;
    }

    h4 {
      font-size: 20px;
    }
  }

  ${up("md")} {
    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 26px;
    }
  }

  ${up("xl")} {
    margin-top: 100px;

    h3 {
      font-size: 36px;
    }

    h4 {
      font-size: 32px;
    }
  }
`;

const Socials = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 12px;

  svg {
    height: 20px;
    transition: 0.2s;
    color: #fff;
    &:hover {
      color: #07a5dc;
    }
  }

  ${up("md")} {
    margin-top: 20px;
    gap: 20px;
    svg {
      height: 26px;
    }
  }

  ${up("lg")} {
    gap: 40px;
    margin-top: 40px;
  }

  ${up("xl")} {
    svg {
      height: 32px;
    }
  }

  
`;

const HeaderImg = styled.div`
  display: flex;
  margin-bottom: 20px;
  img {
    box-shadow: 12px 12px 0px 1px rgba(255, 255, 255, 0.1);
  }

  ${up("base")} {
    width: 40%;
    max-width: 300px;
    img {
      border: 4px solid #f1e5e5;
    }
  }

  ${up("sm")} {
    img {
      border: 6px solid #f1e5e5;
    }
  }

  ${up("md")} {
    width: 40%;
    max-width: 280px;
    margin-bottom: 60px;
    img {
      border-width: 10px;
      box-shadow: 32px 32px 0px 1px rgba(255, 255, 255, 0.1);
    }
  }

  ${up("lg")} {
    img {
      box-shadow: 36px 30px 0px 1px rgba(255, 255, 255, 0.1);
    }
  }

  ${up("xl")} {
    max-width: 400px;
  }
`;
