import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import { useTimeout } from "../../hooks/useTimeout";

type CarouselOptions = {
  autoplay?: boolean;
  delay?: number;
  slides: any[]
}

export const useCarousel = (options: CarouselOptions) => {
  const [emblaRef, embla] = useEmblaCarousel({ loop: false })
  const [currentSlide, setCurrentSlide] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
  const [isRunning] = useState(options.autoplay)

  const { reset } = useTimeout(() => {
    if(!options.delay) return
    if (!embla) return;
    if (embla.canScrollNext()) scrollNext();
    else scrollTo(0);
  }, isRunning ? options.delay : undefined)

  const scrollTo = useCallback(index => {
    reset()
    embla && embla.scrollTo(index)
  }, [embla, reset])

  const scrollPrev = useCallback(() => {
    reset()
    embla && embla.scrollPrev()
  }, [embla, reset])

  const scrollNext = useCallback(() => {
    reset()

    embla && embla.scrollNext()
  }, [embla, reset])

  useEffect(() => {
    if (!embla) return

    const onSelect = () => {
      setCurrentSlide(embla.selectedScrollSnap());
    };

    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
    onSelect();
  }, [embla]);

  useEffect(() => {
    if (embla && embla.slideNodes().length !== options.slides.length) {
      embla.reInit()
      setScrollSnaps(embla.scrollSnapList());
    }
  }, [embla, options.slides])

  return {
    currentSlide,
    scrollSnaps,
    scrollTo,
    scrollNext,
    emblaRef,
    scrollPrev
  }
}
