import {Route, Switch} from "react-router-dom";
import {ContacntUs} from "./ContactUs";
import {DanaStory} from "./DanaStory";
import {FacingObesity} from "./FacingObesity";
import {HomepageView} from "./Homepage";
import {AboutBook} from "./AboutBook";
import {Press} from "./press/Press";
import {Resources} from "./Resources";
import {Bio} from "./bio";
import {BlogPost} from "./BlogPost";
import {Blog} from "./Blog";
import {CanWeTalk} from "./CanWeTalk";
import {SubjectPage} from "./CanWeTalk/SubjectPage";
import {Disclaimer} from "./disclaimer/Disclaimer";

const RootRouter = () => {
    return (
        <>
            <Switch>
                <Route exact path="/" component={HomepageView}/>
                <Route exact path="/facing-obesity" component={FacingObesity}/>
                <Route exact path="/resources" component={Resources}/>
                <Route exact path="/my-story" component={DanaStory}/>
                <Route exact path="/about-book" component={AboutBook}/>
                <Route exact path="/press" component={Press}/>
                <Route exact path="/bio" component={Bio}/>
                <Route exact path="/contact-us" component={ContacntUs}/>
                <Route exact path="/blog/:slug" component={BlogPost}/>
                <Route exact path="/can-we-talk/" component={CanWeTalk}/>
                <Route exact path="/can-we-talk/:id" component={SubjectPage}/>

                <Route exact path="/blog" component={Blog}/>
            </Switch>
            <Disclaimer/>
        </>

    )
        ;
};

export default RootRouter;
