import dayjs from "dayjs";
import styled from "styled-components";

type HeaderProps = {
  title: string;
  authorName: string;
  authorEmail: string;
  createdAt: string;
};

export const SubjectHeader: React.FC<HeaderProps> = ({
  title,
  createdAt,
  authorEmail,
  authorName,
}) => {
  const date = dayjs(createdAt).format("dddd, MMMM, D, YYYY");
  return (
    <CardHeader>
      <Col>
        <h1>{title}</h1>
        <Date>Published on {date}</Date>
      </Col>
      <Col style={{textAlign: 'right'}}>
        <h4>{authorName}</h4>
        <AuthorEmail href={`mailto:${authorEmail}`}>{authorEmail}</AuthorEmail>
      </Col>
    </CardHeader>
  );
};

const CardHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Date = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
  margin-left: 10px;
`;

const AuthorEmail = styled.a`
  color: #07a5dc;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
